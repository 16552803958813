<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">{{edit != null ? "Editar Fabricante" : "Adicionar Fabricante"}}</h4>

          <div class="page-title-right">
            <b-button
              variant="info"
              @click.prevent="$router.push({ name: 'fabricantes' })"
            >
              <i class="bx bx-list-ol font-size-16 align-middle mr-2"></i>
              Listar Fabricantes
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left">
      <div class="col-md-12 col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label>Imagem</label>
                <label class="w-100 cursor-pointer">
                  <div class="uploadimg w-100 border p-5 text-center">
                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                    <h6 class="text-muted">clique para enviar imagem</h6>
                  </div>
                  <input
                    type="file"
                    @change="onFileChange"
                    name="image"
                    multiple
                    class="d-none"
                  />
                </label>
              </div>
              <div class="col-12">
                <div class="table-responsive" v-if="imgs != null">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Imagem</th>
                        <th scope="col" class="text-center">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="text-center align-middle">
                        <td class="text-left align-middle">
                          <b-img
                            width="100"
                            :src="imgs.url"
                            rounded
                            alt=""
                          ></b-img>
                        </td>
                        <td class="align-middle">
                          <b-button
                            variant="danger"
                            @click.prevent="removerImagem()"
                          >
                            <i
                              class="bx bx-trash font-size-16 align-middle"
                            ></i>
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-base">
                <label>
                  <span class="mr-1 text-danger">*</span>
                  Nome
                </label>
                <b-form-group>
                  <b-form-input
                    placeholder="Nome do fabricante"
                    for="text"
                    v-model="form.name"
                    :class="{ 'is-invalid': form && $v.form.name.$error }"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 mb-3">
                <label>Status</label>
                <multiselect
                  v-model="form.status"
                  :options="options_status"
                  class="helo"
                  label="text"
                  placeholder="Status"
                  select-label="Selecionar"
                  deselect-label="Remover"
                ></multiselect>
              </div>
              <div class="col-12 mt-3 text-right">
                <b-button variant="success" @click.prevent="salvarDados()">
                  <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                  Salvar
                </b-button>
                <b-button
                  variant="danger"
                  class="ml-2"
                  @click.prevent="$router.push({ name: 'fabricantes' })"
                >
                  <i class="bx bx-x font-size-16 align-middle mr-2"></i>
                  Cancelar
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import { required } from 'vuelidate/lib/validators'
export default {
  props: {
    edit: {
      type: Object,
      default: function () {
        return null
      },
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      imgs: null,
      form: {
        status: { value: 0, text: 'Ativo' },
        name: null,
      },
      options_status: [
        { value: 0, text: 'Ativo' },
        { value: 1, text: 'Bloqueado' },
      ],
    }
  },
  validations: {
    form: {
      name: { required },
    },
  },
  watch: {
    edit: function () {
      if (this.edit != null) {
        this.form = this.edit
        if (this.edit.image != null) {
          this.imgs = {
            file: null,
            url: this.edit.image[0].image300,
            id: this.edit.image[0].id,
          }
        }
        this.form.status = {
          value: this.edit.status,
          text: this.edit.status == 0 ? 'Ativo' : 'Bloqueado',
        }
      }
    },
  },
  mounted() {
    if (this.edit != null) {
      this.form = this.edit
      if (this.edit.image != null) {
        this.imgs = {
          file: null,
          url: this.edit.image[0].image300,
          id: this.edit.image[0].id,
        }
      }
      this.form.status = {
        value: this.edit.status,
        text: this.edit.status == 0 ? 'Ativo' : 'Bloqueado',
      }
    }
  },
  methods: {
    ...mapActions('manufacturer', [
      'ActionManufacturerAll',
      'ActionManufacturerCreate',
      'ActionManufacturerUpdate',
      'ActionManufacturerDeleteImage',
    ]),
    salvarDados() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.edit != null) {
          this.submitManufacturerUpdate()
        } else {
          this.submitManufacturer()
        }
      }
    },
    async submitManufacturer() {
      try {
        this.form.status = this.form.status.value
        let data = new FormData()
        if (this.imgs != null) {
          data.append('image[]', this.imgs.file)
        }
        data.append('name', this.form.name)
        data.append('status', this.form.status)
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })
        await this.ActionManufacturerCreate(data)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        }).then((_) => {
          this.$router.push({ name: 'fabricantes' })
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    async submitManufacturerUpdate() {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })
        this.form.status = this.form.status.value
        let data = new FormData()
        data.append('id', this.form.id)
        data.append('name', this.form.name)
        data.append('status', this.form.status)
         if (this.imgs != null) {
          data.append('image[]', this.imgs.file)
          if(this.form.image != null){
            this.form.image.forEach(element => {
              this.ActionManufacturerDeleteImage(element.id)
            });
          }
        }
        var dados = {
          data: data,
          id: this.form.id,
        }
        await this.ActionManufacturerUpdate(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        }).then((_) => {
          this.$router.push({ name: 'fabricantes' })
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    onFileChange(e) {
      this.imgs = {
        file: e.target.files[0],
        url: URL.createObjectURL(e.target.files[0]),
        id: null,
      }
    },
    async removerImagem() {
      if (this.imgs.id != null) {
        await this.ActionManufacturerDeleteImage(this.imgs.id)
      }
      this.imgs = null
    },
  },
}
</script>
