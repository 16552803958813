<template>
  <div>
      <Manufacturer/>
  </div>
</template>

<script>
  import Manufacturer from './components/Manufacturer'
  export default {
    components: {
      Manufacturer,
    },
    data() {
      return {
      }
    },
  }
</script>
